<template>
  <div class="checkAssetDetail">
    <ul class="detailUl">
      <li class="detailLi">
        <cell_title_detail head-title="名称" :content-title="assetData.name"></cell_title_detail>
      </li>
      <li class="detailLi">
        <cell_title_detail head-title="设备分类" :content-title="assetData.categoryName"></cell_title_detail>
      </li>
      <li class="detailLi">
        <cell_title_detail head-title="设备品牌" :content-title="assetData.brandName"></cell_title_detail>
      </li>
      <li class="detailLi">
        <cell_title_detail head-title="所属科室" :content-title="assetData.tenantName"></cell_title_detail>
      </li>

      <li class="detailLi">
        <cell_title_detail head-title="资产状态" :content-title="deviceStatus(assetData.status)"></cell_title_detail>
      </li>

      <li class="detailLi">
        <cell_title_detail head-title="使用率" :content-title="assetData.usageRate + '%'"></cell_title_detail>
      </li>
      <li class="detailLi">
        <cell_title_detail head-title="闲置率" :content-title="assetData.idleRate + '%'"></cell_title_detail>
      </li>
      <li class="detailLi">
        <cell_title_detail head-title="使用次数" :content-title="assetData.useNumber + '次'"></cell_title_detail>
      </li>
      <li class="detailLi">
        <cell_title_detail head-title="是否盘点到" :content-title="assetData.inventoried?'已盘点':'未盘点'"></cell_title_detail>
      </li>
      <li class="detailLi">
        <cell_title_detail head-title="当前位置" :content-title="assetData.place"></cell_title_detail>
      </li>
    </ul>
  </div>
</template>

<script>
import Cell_title_detail from '@/components/cell/cell_title_detail/cell_title_detail'

export default {
  name: 'checkAssetDetail',
  components: { Cell_title_detail },
  props: {
    assetData: {
      type: Object,
      default: () => {
        return {
          name: '',
          categoryName: '',
          brandName: '',
          tenantName: '',
          place: '',
          idleRate: 0,
          usageRate: 0,
          inventoried: false,
          status: 0,
          useNumber: 0,
          voltage: 0
        }
      }
    }
  },
  data() {
    return {}
  },

  mounted() {
    this.$nextTick(() => {

    })
  },
  methods: {
    deviceStatus: function(status) {
      if (status == 1) {
        return '开机'
      } else if (status == 2) {
        return '脱落'
      } else if (status == 3) {
        return '报障'
      } else if (status == 4) {
        return '信号丢失'
      } else if (status == 0) {
        return '关机'
      } else if (status == -1) {
        return '未启用'
      } else if (status == -2) {
        return '维修'
      }
      return '-'
    }
  }
}
</script>

<style lang="scss" scoped>

.checkAssetDetail {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 50px;
  position: fixed;
  background-color: white;
  z-index: 2000;

  .detailUl {
    padding: 0;
    list-style: none;
    overflow: scroll;

    .detailLi {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

}

</style>
